@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Colors by Name */
  --graffiti-purple: #9325E5;
  --graffiti-purple-high-opacity: #9325E580;
  --graffiti-purple-low-opacity: #9325E520;
  --graffiti-pink: #DC25E5;
  --graffiti-pink-high-opacity: #DC25E580;
  --graffiti-pink-med-opacity: #DC25E550;
  --graffiti-pink-low-opacity: #DC25E520;
  --graffiti-blue: #271ACD;

  --accent-gold: #FFBB00;
  --accent-light-yellow: #FDF6B6;
  --border-gray: #6C6C6C;
  --border-dark-gray: #484848;
  
  --black-blue:#151934;
  --black-blue-high-opacity:#15193490;
  --dark-black-blue: #0B0D1B;
  --dark-black-blue-low-opacity: #0B0D1B40;



  /* Main Colors */
  --primary-color: #000000;

  /* Background Variables */
  --primary-bg-color: var(--black-blue);

  /* Button Variables */
  --primary-btn-color: red;

  /* Text Variables */
  --primary-text-color: white;

}


body {
  margin: 0;
  padding-top:60px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--black-blue);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
