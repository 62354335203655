.Loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.Loading img {
  width: 30%;
  max-width: 300px;
  margin: 0 auto;
}
