.prevShadow{
    box-shadow: 2px 0px 4px var(--graffiti-pink);
}  
.currShadow{
    box-shadow: 0px 0px 6px var(--graffiti-pink);
}
.nextShadow{
    box-shadow: -2px 0px 4px var(--graffiti-pink);
}  

@media screen and (min-width: 1024px) { 
    .prevShadow{
        box-shadow: 10px 0px 15px var(--graffiti-pink);
    }    
    .currShadow{
        box-shadow: 0px 0px 15px var(--graffiti-pink);
    }
    .nextShadow{
        box-shadow: -10px 0px 15px var(--graffiti-pink);
    }
}
@media screen and (min-width: 768px) { 
    .prevShadow{
        box-shadow: 7px 0px 12px var(--graffiti-pink);
    }    
    .currShadow{
        box-shadow: 0px 0px 12px var(--graffiti-pink);
    }
    .nextShadow{
        box-shadow: -7px 0px 12px var(--graffiti-pink);
    }

}
@media screen and (min-width: 640px) { 
    .prevShadow{
        box-shadow: 5px 0px 8px var(--graffiti-pink);
    }    
    .currShadow{
        box-shadow: 0px 0px 8px var(--graffiti-pink);
    }
    .nextShadow{
        box-shadow: -5px 0px 8px var(--graffiti-pink);
    }
}

  

  